























































































































 import { Notify,Toast,Dialog } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import area from "../../util/area"
 import { WXUserType } from "@/Api/Basics/InterfaceType"
 import Stroage from "@/util/Storage"
 import UpPhoto from "@/Api/UpPhoto/index"
 import { IndexStore } from "@/store/modules/Index"
 import { SetUserData,GetUserData } from "@/Api/Basics/index"
 import { Vue,Component } from "vue-property-decorator"
 import ZoomPage from "@/impView/PageSafety";

 interface VTMessageType<T=string>{
     loadingShow:boolean
     loadingTxt:T;
     userId:T|number;
     token:T;
     HeadImg:T;
     DefaultIcon:T;
     Sex:T;
     Sex1:T;
     Name:T;
     SexNum:number
     Address:T
     Message:T;
     AreaList :any
     SitCodeValue:T;
     Show:boolean
     WxUserInfo:WXUserType

     initData():void;
     handleChangeSex(index:number):void;
     handleClickSite():void
     handleCancel():void;
     handleUpImg(e:any):void;
     handleConfirm(e:any):void;
     handleClickBtn():void;
     handleUpData():void;
     handleError(data:string):void;
     handleWorker( address:string ):void
 }

 @Component({ name:"VTMessage",components:{ HeadTop } })
 export default class VTMessage extends ZoomPage implements VTMessageType{
    loadingShow = false
    loadingTxt = "正在加载中~~"
    userId: string|number = ""
    token = ""
    DefaultIcon = require("$icon/Basics/default.png");
    Sex = require("$icon/Basics/sex.png");
    Sex1 = require("$icon/Basics/sex1.png");

    HeadImg = ""
    Name = ""
    SexNum = 0 // 1 选中男生
    Address = ""
    Message = ""

    AreaList :any = {}
    SitCodeValue = "110000"
    Show = false
    WxUserInfo:WXUserType = {}

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId");
        this.token = Stroage.GetData_ && Stroage.GetData_("token");
        this.initData()
    }

    initData(){
        try{
            let Data = JSON.parse( Stroage.GetData_ && Stroage.GetData_("WXUserInfo") || "{}" )
            this.WxUserInfo = Data
            this.AreaList = area
            this.Name = Data.nick || Data.nickname
            this.SexNum = Number( Data.sex )
            this.HeadImg = Data.headimgurl as string
            console.log( Data )
            let time = setTimeout(()=>{
                let address = "河南 郑州";
                if( ( "province" in Data ) && Data.province ){
                    address = Data.province
                }
                if( ( "city" in Data ) && Data.city ){
                    address += " " + Data.city
                }
                this.Address = address
                this.handleWorker( address )
                clearTimeout( time )
            })
        }catch(cat){ }
    }

    handleChangeSex(index:number){
        if( index != this.SexNum ){
            this.SexNum = index
        }
    }

    handleClickSite(){
        this.Show = !this.Show
    }

    handleCancel(){
        this.Show = false
    }

    handleConfirm(e:any){
        if( Array.isArray( e ) ){
            let Str = ""
            e.forEach( (item,index)=>{
                if( index ){
                    Str += (" " + item.name)
                }else{
                    Str += item.name
                }
            } )
            this.Address = Str
        }
        this.handleCancel()
    }

    handleUpImg(e:any){
        let fromData = new FormData()
        fromData.append("functionName","head")
        fromData.append("file",e.file)
        this.loadingShow = true
        this.loadingTxt = '正在上传图片中...'
        UpPhoto({
            userId:this.userId,
            token:this.token
        },fromData).then(res=>{
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.HeadImg = res.data
            }else{
                this.loadingTxt = '上传失败'
                this.handleError( res.message.msg )
            }
        })
    }

    handleClickBtn(){
        let { HeadImg,Name,Address,Message } = this;
        if( !HeadImg.length ){
            Toast({
            message:"请上传头像",
            icon:"none",
            className:"ToastClassName"
            })
            return;
        }
        if( !Name.length ){
            Toast({
            message:"请输入昵称",
            icon:"none",
            className:"ToastClassName"
            })
            return;
        }
        if( !Address.length ){
            Toast({
            message:"请选择地址",
            icon:"none",
            className:"ToastClassName"
            })
            return;
        }
        if( !Message.length ){
            Dialog.confirm({
            title: '温馨提醒',
            message: '为了让大家更了解你！您确定不输入个性签名么？',
            className:"HideClassName",
            overlayClass:"MaskHideClassName"
            }).then(res=>{
                this.handleUpData()
            }).catch(cat=>cat)
            return
        }
        this.handleUpData()
    }

    handleUpData(){
        let { HeadImg,Name,SexNum,Address,Message,WxUserInfo } = this;
        let UpData = {
            name: Name,
            nickName: Name,
            headImg: HeadImg,
            sex: SexNum,
            address: Address,
            openId: WxUserInfo.openid,
            mood:Message
        }
        this.loadingShow = true
        this.loadingTxt = '数据保存中...'
        new Promise((reslove,reject)=>{
            SetUserData({
                userId:this.userId,
                token:this.token
            },UpData).then(res=>{
                if(res.message.code === "200"){
                    reslove( res )
                }else{
                    this.loadingTxt = '数据保存失败'
                    reject( res )
                }
            })
        }).then(res=>{
            GetUserData({
                userId:this.userId,
                token:this.token
            }).then(res=>{
                this.loadingShow = false
                if( res.message.code === "200" ){
                    Stroage.SetData_ && Stroage.SetData_("UserData",JSON.stringify( res.data ))
                    if ( !(res.data.schoolName && res.data.school ) ){ // 跳转到 绑定学校
                        Toast({
                            message:"跳转绑定学校",
                            icon:"none",
                            className:"ToastClassName"
                        })
                        let time = setTimeout(()=>{
                            this.$router.replace({
                                path:"/school",
                                query:{
                                    BackBool:"true"
                                }
                            })
                            clearTimeout(time)
                        },500)
                        return;
                    }else{
                        Toast({
                            message:"绑定成功",
                            icon:"none",
                            className:"ToastClassName"
                        })
                        IndexStore.SetLoading( true )
                        let time = setTimeout(()=>{
                            this.$router.replace({
                                name:"/home",
                            })
                            clearTimeout(time)
                        },500)
                    }
                }else{
                    this.loadingTxt = '数据保存失败'
                    this.handleError( res.message.msg )
                }
            })
        }).catch(cat=>{
            this.handleError( cat.message.msg )
        })

    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }

    handleWorker( address:string ){
        if( !address ){ this.SitCodeValue = "110000"; return;}
        import("../../Worker/worker").then(res=>res.FilterAddCode(address,this.AreaList)).then(res=>{
            if( res ){
                this.SitCodeValue = res
            }
        })
    }
}
